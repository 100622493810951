/* Modal container for the invoice */
.invoice-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 800px;
  padding: 20px;
}

/* Content area of the invoice modal */
.invoice-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Heading styles */
.invoice-modal-content h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

/* Paragraph styles for invoice details */
.invoice-modal-content p {
  margin: 8px 0;
  font-size: 1em;
  color: #555;
}

/* Payment list */
.invoice-modal-content ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  width: 100%;
}

.invoice-modal-content ul li {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 1em;
  color: #333;
}

/* Actions at the bottom of the invoice modal */
.invoice-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Print and close buttons */
.invoice-actions button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.invoice-actions button:first-child {
  background-color: #007bff;
  color: white;
}

.invoice-actions button:first-child:hover {
  background-color: #0056b3;
}

.invoice-actions button:last-child {
  background-color: #f0f0f0;
  color: #333;
}

.invoice-actions button:last-child:hover {
  background-color: #e0e0e0;
}

/* Media queries for responsive design */
@media (max-width: 600px) {
  .invoice-modal {
    width: 95%;
  }

  .invoice-actions {
    flex-direction: column;
  }

  .invoice-actions button {
    width: 100%;
    margin-top: 10px;
  }

  .invoice-actions button:first-child {
    margin-top: 0;
  }
}
