/* Top Bar */
.top-bar {
  background-color: #343a40;
  color: #ffffff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #17a2b8;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  height: 50px; /* Define a fixed height for the top bar */
}

.top-bar-left {
  display: flex;
  align-items: center;
}

.menu-icon {
  font-size: 1.5rem;
  margin-right: 20px;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.top-bar-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.top-bar-right a {
  color: #ffffff;
  margin-left: 15px;
  text-decoration: none;
  font-size: 1rem;
}

.top-bar-right a:hover {
  color: #17a2b8;
}

.dark-mode-switch {
  background: none;
  border: none;
  color: #ffffff;
  margin-right: 15px;
  cursor: pointer;
  font-size: 1rem;
}

/* Sidebar */
.sidebar {
  background-color: #f8f9fa;
  height: 100vh;
  padding-top: 60px; /* Adjusted for top bar */
  border-right: 2px solid #17a2b8;
  position: fixed;
  top: 50px; /* Adjusted for top bar height */
  left: 0;
  z-index: 500;
  width: 220px;
}

.sidebar .nav-link {
  color: #343a40;
  font-weight: 500;
  padding: 15px;
  text-align: left;
  font-size: 1.1rem;
}

.sidebar .nav-link:hover {
  background-color: #17a2b8;
  color: #ffffff;
  border-radius: 0;
}

.sidebar .nav-link.active {
  background-color: #17a2b8;
  color: #ffffff;
}

/* Main Content Area */
.main-content {
  padding: 20px;
  padding-top: 70px; /* Add padding to ensure content is not hidden under the top bar */
  background-color: #f1f1f1;
  min-height: 100vh;
  transition: margin-left 0.3s ease;
}

.content-with-sidebar {
  margin-left: 220px; /* Adjusted for sidebar width */
}

.content-full-width {
  margin-left: 0;
}

/* Dark Mode */
.dark-mode .top-bar {
  background-color: #1c1c1c;
  border-bottom: 2px solid #ffc107;
}

.dark-mode .top-bar-title,
.dark-mode .top-bar-right a,
.dark-mode .menu-icon,
.dark-mode .dark-mode-switch {
  color: #ffc107;
}

.dark-mode .sidebar {
  background-color: #2e2e2e;
  border-right: 2px solid #ffc107;
}

.dark-mode .sidebar .nav-link {
  color: #ffc107;
}

.dark-mode .sidebar .nav-link:hover,
.dark-mode .sidebar .nav-link.active {
  background-color: #ffc107;
  color: #2e2e2e;
}

.dark-mode .main-content {
  background-color: #1c1c1c;
  color: #ffffff;
}

/* Responsive Sidebar */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    padding-top: 0;
  }
  .sidebar .nav-link {
    padding: 10px;
    text-align: center;
  }
  .main-content {
    margin-left: 0;
    padding-top: 60px; /* Adjust for top bar in mobile view */
  }
}
