.dashboard-container {
  padding: 20px;
}

.quick-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.quick-actions button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quick-actions button:hover {
  background-color: #0056b3;
}

.metrics-overview {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.metrics-overview .card {
  flex: 1;
  background-color: #f8f9fa;
  padding: 20px;
  margin-right: 10px;
  text-align: center;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.metrics-overview .card:last-child {
  margin-right: 0;
}

.metrics-overview .card:hover {
  transform: scale(1.05);
}

.metrics-overview .card h3 {
  margin-bottom: 10px;
}

.metrics-overview .card p {
  font-size: 24px;
  font-weight: bold;
}

.charts-overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.charts-overview h2 {
  width: 100%;
  margin-bottom: 10px;
}

.charts-overview canvas {
  max-width: 100%;
  height: auto;
  flex: 1 1 400px;
}

/* Recent Activity Section */
.recent-activity {
  margin-top: 20px;
  display: flex;
}

.activity-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.activity-table th,
.activity-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.activity-table th {
  background-color: grey;

  text-align: left;
}

.activity-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.activity-table tr:hover {
  background-color: #ddd;
}

.listcard {
  flex: 1;
  background-color: #f8f9fa;
  padding: 20px;
  margin-right: 10px;
  text-align: center;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.chartcard {
  flex: 1;
  background-color: #f8f9fa;
  padding: 20px;
  margin-right: 10px;
  text-align: center;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.chartcard:hover {
  transform: scale(1.05);
}
.listcard:hover {
  transform: scale(1.05);
}
