.credit-container {
  padding: 20px;
}

.credit-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.credit-table th,
.credit-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.credit-table th {
  background-color: #f2f2f2;
}

.pay-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.pay-btn:hover {
  background-color: #218838;
}
