/* Main Cars Container */
.cars-container {
  padding: 20px;
}

.cars-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

/* Cars Table */
.cars-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.cars-table th,
.cars-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.cars-table th {
  background-color: #f2f2f2;
  color: #333;
}

.cars-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.cars-table tr:hover {
  background-color: #f1f1f1;
}

.cars-table td {
  color: #555;
}

.cars-table td .edit-btn,
.cars-table td .delete-btn {
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cars-table td .edit-btn {
  background-color: #ffc107;
  color: #fff;
}

.cars-table td .edit-btn:hover {
  background-color: #e0a800;
}

.cars-table td .delete-btn {
  background-color: #dc3545;
  color: #fff;
}

.cars-table td .delete-btn:hover {
  background-color: #c82333;
}

/* Add Car Button */
.add-car {
  text-align: center;
  position: absolute;
}

.add-car button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.add-car button:hover {
  background-color: #218838;
}
