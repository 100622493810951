.reports-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.reports-header {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.header-item {
  display: flex;
  flex-direction: column;
}

.header-item input {
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.header-item select {
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.save-button,
.load-button,
.delete-button {
  margin-top: 10px;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.save-button {
  background-color: #28a745;
  color: white;
}

.load-button {
  background-color: #6f42c1;
  color: white;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  margin-left: 10px;
}

.reports-actions {
  margin-bottom: 20px;
}

.get-stats-button,
.add-filter-button {
  padding: 10px 15px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.get-stats-button {
  background-color: #6f42c1;
  color: white;
}

.add-filter-button {
  background-color: #17a2b8;
  color: white;
}

.reports-stats {
  margin-top: 20px;
}

.stats-table {
  width: 100%;
  border-collapse: collapse;
}

.stats-table th,
.stats-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.stats-table th {
  background-color: #f8f9fa;
}

.stats-table tfoot td {
  font-weight: bold;
}

.reports-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.reports-pagination select {
  padding: 5px;
}
